<template>
  <base-material-card
    icon="far fa-medal"
    title="Beste Turner"
    color="primary"
  >
    <p>Eine Liste mit den besten Turnern um diese nach dem Wettkampf zu küren.</p>
    <p>Im Score-System werden zwei Seiten generiert: nach erturnten Punkten und nach Score-Punkten.</p>
    <v-btn block @click="besteturner(e)">Download</v-btn>
  </base-material-card>
</template>

<script>
import { usePdf } from '../../../plugins/pdf'

export default {
  name: 'besteturner',

  setup (props, context) {
    return {
      ...usePdf(context)
    }
  },

  props: {
    id: {
      type: String,
      required: true
    },
    r: {
      type: Array,
      required: true
    },
    e: {
      type: Object,
      required: true
    },
    df: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
